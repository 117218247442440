<template>
    <div>
        <p class="text-sm m-0 mt--4">
            Pas encore de compte ?
            <router-link :to="{name: 'sign_up'}">Inscrivez-vous ici</router-link>
        </p>
        <p class="text-sm m-0 mb-4">
            Mot de passe oublié ?
            <router-link :to="{name: 'forgot_password'}">Changez-le ici</router-link>
        </p>
        <b-col lg="8" offset-lg="2">
            <b-form @submit.prevent="submit">
                <b-form-group>
                    <b-form-input v-model="form.username" type="email" required placeholder="e-mail"/>
                </b-form-group>

                <b-form-group>
                    <b-form-input v-model="form.password" type="password" required placeholder="mot de passe" autocomplete="current-password"/>
                </b-form-group>

                <div class="mb-3 text-left">
                    <b-form-checkbox v-model="form.rememberMe" :value="true" :unchecked-value="false">
                        <span class="ml--3">Se souvenir de moi</span>
                    </b-form-checkbox>
                </div>

                <b-btn type="submit" variant="secondary" class="mb-3">
                    <font-awesome-icon icon="sign-in-alt"/>
                    Connexion
                </b-btn>
            </b-form>
        </b-col>
    </div>
</template>

<script>
    import http                from '@/util/http';
    import auth                from '@/util/auth';
    import alert               from '@/util/alert';
    import {initNotifications} from '@/util/firebase';

    export default {
        name: 'Login',
        props: {
            defaultRedirect: {
                default: () => ({name: 'dashboard_home'})
            }
        },
        data: () => ({
            redirectTo: null,
            form: {
                username: null,
                password: null,
                rememberMe: false
            }
        }),
        methods: {
            postAuthRedirect() {
                this.$router.push(this.redirectTo || this.defaultRedirect);
            },
            submit() {
                alert.loading();
                this.axios.post(http.apiPath('api_login_check'), this.form)
                    .then(response => this.$store.dispatch('user/login', response.data.token))
                    .then(auth.refreshSession)
                    .then(() => this.postAuthRedirect())
                    .then(() => console.log(
                        '       _..._                        .---.     \n' +
                        '    .-\'_..._\'\'. .---.               |   |     \n' +
                        '  .\' .\'      \'.\\|   |               \'---\'.--. \n' +
                        ' / .\'           |   |               .---.|__| \n' +
                        '. \'             |   |               |   |.--. \n' +
                        '| |             |   |               |   ||  | \n' +
                        '| |             |   |   _    _      |   ||  | \n' +
                        '. \'             |   |  | \'  / |     |   ||  | \n' +
                        ' \\ \'.          .|   | .\' | .\' |     |   ||  | \n' +
                        '  \'. `._____.-\'/|   | /  | /  |     |   ||__| \n' +
                        '    `-.______ / \'---\'|   `\'.  |  __.\'   \'     \n' +
                        '             `       \'   .\'|  \'/|      \'      \n' +
                        '                      `-\'  `--\' |____.\'       \n' +
                        '\n' +
                        'Bonjour jeune joueur égaré.' +
                        '\n' +
                        '\n' +
                        'Si tu cherches une récompense à ajouter à ta collection, rends-toi sur cette page : ' +
                        http.frontPath('easter_egg_easy') +
                        '\n' +
                        '\n' +
                        'Et si cela peut attiser ta curiosité, sache qu\'une 2e est cachée dans le code de ce site, plus dure à trouver ;)'
                    ))
                    .catch(() => this.$toaster.error('Identifiants de connexion incorrects'))
                    .then(initNotifications)
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.redirectTo = this.$route.query.redirectTo || null;
            if (auth.isLoggedIn()) this.postAuthRedirect();
        }
    };
</script>

<style scoped>
    .mt--4 {
        margin-top: -2rem !important;
    }
</style>